const _transparent = "transparent"
const _current = "currentColor"
const _white = "#FFFFFF"
const _black = "#000000"
const _orange = {"50":"#FEFFEB","100":"#FDE0D8","150":"#FDD1C4","200":"#FCC1B1","300":"#FAA289","400":"#F98362","600":"#F7643B","DEFAULT":"#F7643B"}
const _purple = {"50":"#F6F3FD","100":"#EDE8FC","200":"#DAD0F8","300":"#C8B9F5","400":"#B5A1F1","500":"#A38AEE","600":"#7B5DD4","650":"#624AAA","700":"#433863","DEFAULT":"#7B5DD4"}
const _neutral = {"50":"#F4F4F3","100":"#EDEDEB","150":"#E4E4E1","200":"#DBDBD7","300":"#C8C8C3","400":"#B6B6AF","500":"#A4A49B","600":"#85857E","700":"#676761","800":"#494945","850":"#2A2A28","900":"#1B1B19"}
const _green = "#087B05"
const _yellow = "#FFC700"
const _red = "#D40000"
const _blue = "#0077CC"
const config = { "transparent": _transparent, "current": _current, "white": _white, "black": _black, "orange": _orange, "purple": _purple, "neutral": _neutral, "green": _green, "yellow": _yellow, "red": _red, "blue": _blue,  }
export { config as default, _transparent, _current, _white, _black, _orange, _purple, _neutral, _green, _yellow, _red, _blue }